<template>
  <div class="personnel">
    <div v-if="popStatus">
      <modalView @modalHide="modalHide"></modalView>
    </div>
    <Banner :list="banners"></Banner>
    <div class="container">
      <div class="personnel-title text-center">
        <h1>优普道 让资质下证变得更便捷安全</h1>
        <span>办理流程的一体化，让建企省时省心，招投标变得更简单</span>
      </div>
      <div class="personnel-from position-relative">
        <div class="personnel-from-box flex justify-between align-center">
          <div class="personnel-from-l">
            <div class="flex align-center">
              <span class="personnel-from-l-span">
                所在城市
              </span>
              <div>
                <a-select v-model="fromData.sheng" style="width: 144px;margin-right: 14px;" @change="handleChange">
                  <template v-for="(item, index) in areaData">
                    <a-select-option :value="item.name" :key="index">
                      {{ item.name }}
                    </a-select-option>
                  </template>
                </a-select>

                <a-select v-model="fromData.city" style="width: 144px">
                  <template v-for="(item, index) in children">
                    <a-select-option :value="item.name" :key="index">
                      {{ item.name }}
                    </a-select-option>
                  </template>
                </a-select>
              </div>
            </div>

            <div class="flex align-center justify-between mt-3">
              <span class="personnel-from-l-span">
                资质类型
              </span>
              <div style="width: 100%;" class="flex-1">
                <a-select placeholder="请选择资质类型" v-model="fromData.mytype" style="width: 100%;" @change="typeChange">
                  <template v-for="(item, index) in applyType">
                    <a-select-option :value="item.id" :key="index">
                      {{ item.name }}
                    </a-select-option>
                  </template>
                </a-select>
              </div>
            </div>

            <div class="flex align-center justify-between mt-3">
              <span class="personnel-from-l-span">
                工程师类型
              </span>
              <div style="width: 100%;" class="flex-1 border">
                <a-input class="px-1" v-model="fromData.type" placeholder="请输入工程师类型" />
              </div>
            </div>

            <div class="flex align-center justify-between mt-3">
              <span class="personnel-from-l-span">
                工程师数量
              </span>
              <div style="width: 100%;" class="flex-1 border">
                <a-input v-model="fromData.nub" class="px-1" placeholder="请输入工程师数量" />
              </div>
            </div>

            <div class="flex align-center justify-between mt-3">
              <span class="personnel-from-l-span">
                手机号码
              </span>
              <div style="width: 100%;" class="flex-1 border">
                <a-input v-model="fromData.phone" class="px-1" placeholder="请输入手机号码" />
              </div>
            </div>

            <span class="personnel-from-test-b">* 为了您的隐私和权益，以上信息将被严格保密。</span>
          </div>
          <div class="personnel-from-c">
            <!-- <img src="../../assets/image/personnel/06001.png" class="position-absolute" /> -->
            <div class="personnel-from-c-btn wow pulse" data-wow-iteration="infinite" @click="getInfo">
              <div class="personnel-from-c-btn-c">
                <p class="p">开始</p>
                <p>计算</p>
              </div>
            </div>
          </div>
          <div class="personnel-from-r">
            <h1 class="personnel-from-r-title mb-2">您所需的工程师人才费用约为</h1>
            <h3 class="personnel-from-r-nub mb-2">{{ math }}<span>元</span></h3>
            <div class="personnel-from-r-cont">
              <div class="list flex justify-between align-center">
                <span>咨询费</span>
                <span>{{ cost.consulting }}</span>
                <span>元</span>
              </div>
              <div class="list flex justify-between align-center">
                <span>培训费</span>
                <span>{{ cost.train }}</span>
                <span>元</span>
              </div>
              <div class="list flex justify-between align-center">
                <span>资料费</span>
                <span>{{ cost.data }}</span>
                <span>元</span>
              </div>
            </div>
            <!-- 手机 -->
            <div class="container">
              <div class="personnel-from-r-cont-m">
                <h3>{{ math }}<span>元</span></h3>
                <p>
                  咨询费 <span>{{ cost.consulting }}</span> 培训费 <span>{{ cost.train }}</span> 资料费 <span>{{ cost.data }}</span>
                </p>
              </div>
            </div>
            <span class="personnel-from-test-b">为人才费用的估算数据由优普道集团建筑人才库中的10w+建工人才项目费用数据实时分析计算</span>
          </div>
        </div>
        <img src="../../assets/image/personnel/06001.png" class="position-absolute posi-img" />
        <div class="personnel-from-btn m-auto" @click="getInfo">开始计算</div>
      </div>
    </div>

    <!--  -->
    <div class="personnel-swiper">
      <div class="container">
        <div class="flex personnel-box">
          <div class="personnel-swiper-l">
            <!-- <img src="@/assets/image/personnel/06003.png" /> -->
            <div class="swiper-container banner-container">
              <div class="swiper-wrapper">
                <div class="swiper-slide bg-cent">
                  <img src="@/assets/image/personnel/06003.png" />
                </div>
              </div>
            </div>
          </div>
          <div class="personnel-swiper-r">
            <h1>
              <span class="test-y text-color-f">15</span>
              <span>年的努力与坚持 我们得到了</span>
              <span class="test-y text-color-f">10万</span>
              <span class="test-y test-t text-color-f">+</span>
              <span class="ml-2">建企和</span>
              <span class="test-y text-color-f">30万</span>
              <span class="test-y test-t text-color-f">+</span>
              <span class="ml-2">建工人才的认可</span>
            </h1>
            <div class="personnel-swiper-r-cent text-center  flex justify-between align-center">
              <div class="personnel-swiper-r-cent-list cursor-pointer" v-for="(item, index) in lists" :key="index">
                <div class="box p-1">
                  <img :src="item.img" />
                  <p>{{ item.title }}</p>
                  <p>{{ item.test }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="personnel-serve bg-cent">
      <div class="personnel-serve-box text-center">
        <h1>优普道的“三懂四强”</h1>
        <div class="personnel-serve-box-img">
          <div class="personnel-serve-box-img-bg wow bounceInLeft animated bg-cent" :style="{ backgroundImage: 'url(' + require('@/assets/image/personnel/34.png') + ')' }">
            <!-- <img class="img-l wow bounceInLeft animated" src="../../assets/image/personnel/34.png" /> -->
          </div>
          <div class="personnel-serve-box-img-bg-m">
            <img src="@/assets/image/personnel/006.png" />
          </div>
          <!-- <img class="img-r wow bounceInRight animated" src="../../assets/image/personnel/06010.png" /> -->
        </div>
        <div class="btn-box flex justify-center align-center" @click="$toConnectLink">
          <div class="btn btn-l cursor-pointer mr-2">立即咨询</div>
          <div class="btn btn-r cursor-pointer ml-2">定制服务</div>
        </div>
      </div>
    </div>

    <div class="personnel-servepick">
      <div class="container">
        <div class="personnel-servepick-box text-center">
          <h1>专业服务对比</h1>
          <h3>专注建企服务15年，行业累积建企人才10w+</h3>
          <!-- pc -->
          <div class="servepick-pc">
            <div class="mt-5 img-list wow bounceInDown animated">
              <img width="100%" src="../../assets/image/personnel/06013.png" />
            </div>
            <div class="mt-5 img-list wow bounceInDown animated">
              <img width="100%" src="../../assets/image/personnel/06014.png" />
            </div>
            <div class="mt-5 img-list wow bounceInDown animated">
              <img width="100%" src="../../assets/image/personnel/06015.png" />
            </div>
            <div class="mt-5 img-list wow bounceInDown animated">
              <img width="100%" src="../../assets/image/personnel/06016.png" />
            </div>
          </div>

          <!-- 手机 -->
          <div class="servepick-m">
            <div class="mt-5 img-list wow bounceInDown animated">
              <img width="100%" src="../../assets/image/personnel/007.png" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="personnel-shcool bg-cent">
      <div class="container">
        <div class="personnel-shcool-box text-center">
          <h1>优普道网校</h1>
          <h3>专注建工行业服务，助力打造建企人才</h3>
          <div class="personnel-shcool-cent flex justify-between  align-center mt-5">
            <div class="personnel-shcool-box-l">
              <img src="../../assets/image/personnel/06017.png" />
            </div>
            <div class="personnel-shcool-box-r flex justify-between align-center">
              <div class="list cursor-pointer" v-for="(item, index) in datas" :key="index">
                <div class="list-img">
                  <img :src="item.img" />
                </div>
                <p class="test-hide">{{ item.title }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="study-btn flex justify-center align-center" @click="$toConnectLink">
      <button class="mr-2 btn-l">立即咨询</button>
      <button class="ml-2 btn-r">加入我们</button>
    </div>
  </div>
</template>

<script>
let setTime;
import modalView from "@/components/common/modal.vue";
import area from "@/assets/json/areaData.json";
import { WOW } from "wowjs";
import Swiper from "swiper";
import Banner from "@/components/common/banner";
export default {
  components: {
    Banner,
    modalView,
  },
  data() {
    return {
      popStatus: false,
      lists: [
        {
          img: require("@/assets/image/serve/06004.png"),
          title: "30345/建企",
          test: "服务",
        },
        {
          img: require("@/assets/image/serve/06005.png"),
          title: "102341/人才",
          test: "服务",
        },
        {
          img: require("@/assets/image/serve/06007.png"),
          title: "54732/证书",
          test: "储备",
        },
        {
          img: require("@/assets/image/serve/06008.png"),
          title: "30345/学员",
          test: "培养",
        },
      ],
      children: [],
      areaData: area,
      datas: [
        {
          img: require("@/assets/image/study/06018.png"),
          title: "一级建造师",
        },
        {
          img: require("@/assets/image/study/06019.png"),
          title: "二级建造师",
        },
        {
          img: require("@/assets/image/study/06020.png"),
          title: "湖南中级工程师",
        },
        {
          img: require("@/assets/image/study/06021.png"),
          title: "消防设备操作员",
        },
        {
          img: require("@/assets/image/study/06022.png"),
          title: "BIM工程师",
        },
        {
          img: require("@/assets/image/study/06023.png"),
          title: "消防工程师",
        },
        {
          img: require("@/assets/image/study/06024.png"),
          title: "监理工程师",
        },
        {
          img: require("@/assets/image/study/06025.png"),
          title: "一级造价工程师",
        },
        {
          img: require("@/assets/image/study/06026.png"),
          title: "二级造价工程师",
        },
      ],
      cost: {
        consulting: "",
        train: "",
        data: "",
      },
      math: "",
      fromData: {
        sheng: "北京市",
        city: "",
        mytype: "",
        type: "",
        name: "",
        phone: "",
      },
      banners: {
        title: "建筑人才行业深耕15余年",
        desc: "价格公开透明   全国拥有专业技术型人才储备30000+",
        list: [require("@/assets/image/banner/3.jpg")],
      },
    };
  },
  methods: {
    modalHide() {
      this.popStatus = false;
    },
    // 获得类型 资质类型
    applyCategory() {
      this.$api.applyCategory().then((res) => {
        this.applyType = res.data;
        this.fromData.mytype = res.data[0].name;
      });
    },
    // 提交信息
    getInfo() {
      let source_url = window.location.href;
      if (!/^1[3456789]\d{9}$/.test(this.fromData.phone)) {
        this.$message.error("请输入正确手机号");
        return;
      }

      this.fromData.source_url = source_url;
      // this.fromData.source_url =  10 ;
      this.$api
        .applyOffer(this.fromData)
        .then((res) => {
          this.$message.success("提交成功，稍后专业顾问将为你提供专业报价");
          this.popStatus = true;
          clearInterval(setTime);
          this.cost = {
            consulting: "???",
            train: "???",
            data: "???",
          };
          this.math = "???";
        })
        .catch((err) => {
          this.$message.error("提交失败", err);
        });
    },
    _init_swiper() {
      var swiper = new Swiper(".banner-container", {
        autoplay: 2000,
        loop: false,
        pagination: ".swiper-pagination",
      });
    },
    mathNub(strat, end) {
      return Math.round(Math.random() * strat + end);
    },
    handleChange(value) {
      let data = area.filter((item) => {
        return item.name == value;
      });
      this.children = data[0].children[0].children;
      this.fromData.city = data[0].children[0].children[0].name;
    },
    typeChange() {},
    getBannerList() {
      this.$api
        .getBannerList({
          // 10首页  20
          launchPage: 12,
          launchFacility: 10,
        })
        .then((res) => {
          this.banners.list = res.data.list;
        });
    },
  },
  mounted() {
    var wow = new WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: true,
      live: true,
    });
    wow.init();
    this._init_swiper();
    this.applyCategory();
  },
  created() {
    let data = area.filter((item) => {
      return item.name == this.fromData.sheng;
    });

    this.children = data[0].children[0].children;
    this.fromData.city = data[0].children[0].children[0].name;

    setTime = setInterval(() => {
      this.math = this.mathNub(100000, 10000);
      this.cost = {
        consulting: this.mathNub(10000, 1000),
        train: this.mathNub(10000, 1000),
        data: this.mathNub(10000, 1000),
      };
    }, 1000);
    this.getBannerList();
  },
};
</script>

<style scoped lang="scss">
.study-btn {
  margin-bottom: 90px;
  button {
    width: 276px;
    height: 56px;
    background: #ffffff;
    border: 1px solid #2070ff;
    border-radius: 10px;
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #2070ff;
    line-height: 56px;
    transition: all 0.6s;
  }
  .btn-r {
    color: #ffffff;
    background: #2070ff;
    &:hover {
      color: #ffffff;
      background-color: #ff5a00;
      border: none;
    }
  }
  .btn-l {
    &:hover {
      color: #ffffff;
      background-color: #ff5a00;
      border: none;
    }
  }
}
.personnel {
  .personnel-from-btn {
    display: none;
  }
  .personnel-title {
    padding: 70px 0 20px 0;
    width: 100%;
    position: relative;
    top: -70px;
    background: #fff;
    z-index: 9;
    border: 1px solid #eee;
    -o-border-image: linear-gradient(#eee, #fff) 0 60;
    border-image: linear-gradient(#eee, #fff) 0 60;
    border-bottom: 0;

    h1 {
      font-size: 36px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #383d41;
    }

    span {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #686a73;
    }
  }

  .personnel-from {
    height: 440px;

    .posi-img {
      left: 50%;
      z-index: -1;
      top: -130px;
    }

    .personnel-from-test-b {
      margin-top: 40px;
      display: block;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #bbbbbb;
    }

    &-l {
      width: 50%;
      padding: 0 65px;

      .personnel-from-l-span {
        width: 100px;
      }
    }

    &-c {
      left: 50%;
      top: 0;

      &-btn {
        width: 94px;
        height: 94px;
        background: rgba(127, 187, 255, 0.2);
        border-radius: 50%;
        padding: 5px;
        cursor: pointer;

        &-c {
          width: 100%;
          height: 100%;
          background: linear-gradient(160deg, #4ee0f5, #7fbbff, #2d8bff, #5e9cff, #0476f8);
          border-radius: 50%;
          font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
          text-align: center;

          .p {
            padding-top: 15%;
          }
        }
      }
    }

    &-r {
      padding: 0 65px;
      width: 50%;

      &-title {
        text-align: center;
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #616266;
      }

      &-nub {
        text-align: center;
        font-size: 40px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #ff5a00;

        span {
          font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #616266;
        }
      }

      &-cont {
        width: 387px;
        // height: 150px;
        background: #f6f6f6;
        border-radius: 6px;
        padding: 15px 0;

        .list {
          padding: 10px 70px;
        }

        span {
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #616266;

          &:nth-child(2) {
            color: #ff5a00;
          }
        }
      }
      &-cont-m {
        display: none;
      }
    }
  }

  .personnel-swiper {
    padding: 70px 0 50px 0;
    background-color: #fbfbfb;

    &-l {
      margin-right: 25px;
      width: 500px;
    }

    &-r {
      h1 {
        font-size: 19px;
        font-family: HYYaKuHeiW;
        font-weight: 400;
        font-style: italic;
        position: relative;

        .test-y {
          font-size: 30px;
          font-family: HYYaKuHeiW;
        }

        .test-t {
          position: absolute;
          top: -10px;
        }
      }

      &-cent {
        margin-top: 80px;

        &-list {
          &:hover .box {
            transform: scale(1.1, 1.1);
            // background: red;
          }

          .box {
            transition: all 0.6s;
          }

          img {
            width: 86px;
            height: 86px;
          }

          p {
            font-size: 20px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #616266;
          }
        }
      }
    }
  }

  .personnel-serve {
    padding: 90px 0 80px 0;
    width: 100%;
    // height: 590px;
    background-image: url(../../assets/image/personnel/06012.png);

    &-box {
      &-img {
        height: 330px;
        position: relative;
        top: 0;
        &-bg {
          height: 170px;
          width: 100%;
          position: absolute;
          top: 20%;
          left: 0;
          width: 100%;
        }
        &-bg-m {
          display: none;
        }
        .img-l {
          // width: 50%;
          position: absolute;
          // margin-right: -110px;
          top: 20%;
          left: 0;
          width: 100%;
          // right: 50%;
        }

        .img-r {
          // width: 50%;
          top: 40%;
          position: absolute;
          margin-left: -110px;
          left: 50%;
        }
      }

      h1 {
        font-size: 36px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #ffffff;
      }

      .btn-box {
        .btn {
          width: 276px;
          height: 56px;
          background: #5b40e2;
          border: 1px solid #ffffff;
          border-radius: 10px;
          font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
          line-height: 56px;
          transition: all 0.6s;

          &:hover {
            color: #2070ff;
            background-color: #ff6600;
          }
        }

        .btn-r {
          color: #2070ff;
          background-color: #ffffff;
        }
      }
    }
  }

  .personnel-servepick {
    padding: 90px 0;

    &-box {
      h1 {
        text-align: center;
        font-size: 36px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #383d41;
      }

      h3 {
        text-align: center;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #686a73;
        margin-top: 10px;
      }

      .img-list {
        width: 100%;
      }
      .servepick-m {
        display: none;
      }
    }
  }

  .personnel-shcool {
    background-image: url(../../assets/image/personnel/06027.png);
    padding: 100px 0;

    h1 {
      text-align: center;
      font-size: 36px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #383d41;
    }

    h3 {
      text-align: center;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #686a73;
      margin-top: 10px;
    }
    .personnel-shcool-box-l {
      width: 50%;
      img {
        width: 100%;
      }
    }
    .personnel-shcool-box-r {
      width: 50%;
      // flex: 0 0 33%;
      flex-wrap: wrap;
      .list {
        width: 30%;
        text-align: center;
        &:hover img {
          transform: scale(1.1, 1.1);
        }
        // height: 80px;
        &-img {
          margin: 0 auto;
          width: 130px;
          height: 80px;
          overflow: hidden;
          img {
            width: 100%;
            transition: all 0.6s;
          }
        }
        p {
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #686a73;
          margin-top: 10px;
          margin-bottom: 15px;
        }
      }
      .list-w {
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .personnel .personnel-from .posi-img {
    display: none;
  }
}

@media only screen and (max-width: 1024px) {
  .personnel {
    .personnel-from {
      height: auto;
      .personnel-from-box {
        flex-wrap: wrap-reverse;
        padding-bottom: 40px;
      }
      .personnel-from-btn {
        display: block;
        width: 305px;
        height: 40px;
        background: #2070ff;
        border-radius: 3px;
        font-size: 13px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #f6f6f6;
        line-height: 40px;
        text-align: center;
        margin-bottom: 50px;
      }
    }
    .personnel-from-r {
      padding: 0px;
      width: 100%;
      margin: 0 auto;
      .personnel-from-r-cont {
        margin: 0 auto;
      }
      .personnel-from-test-b {
        text-align: center;
        margin-bottom: 30px;
      }
    }
    .personnel-from-l {
      width: auto;
      padding: 0;
      margin: 0 auto;
      .ant-select {
        width: auto !important;
      }
    }
    .personnel-from-c {
      display: none;
    }

    .personnel-swiper {
      .personnel-box {
        flex-wrap: wrap;
        .personnel-swiper-l {
          width: 100%;
          margin: 0 auto;
          margin-bottom: 30px;
          .swiper-slide {
            width: 100% !important;
          }
          img {
            margin: 0 auto;
          }
        }
        .personnel-swiper-r {
          margin: 0 auto;
          .personnel-swiper-r-cent {
            flex-wrap: wrap;
          }
        }
      }
    }

    .personnel-shcool-cent {
      flex-wrap: wrap;
      .personnel-shcool-box-l {
        width: 100%;
        img {
          width: 60%;
        }
        margin-bottom: 30px;
      }
      .personnel-shcool-box-r {
        width: 100%;
        .list-img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}

@media only screen and (max-width: 750px) {
}

@media only screen and (max-width: 481px) {
  .personnel {
    .personnel-title {
      display: none;
    }
    .personnel-from {
      position: relative;
      top: -30px;
      z-index: 2;
      background: #ffffff;
      padding-top: 20px;
      .personnel-from-box {
        flex-wrap: wrap-reverse;
        padding-bottom: 40px;
      }
      .personnel-from-btn {
        display: block;
        width: 305px;
        height: 40px;
        background: #2070ff;
        border-radius: 3px;
        font-size: 13px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #f6f6f6;
        line-height: 40px;
        text-align: center;
        margin-bottom: 50px;
      }
    }
    .personnel-from-r {
      padding: 0px;
      width: 100%;
      margin: 0 auto;
      .personnel-from-r-nub {
        display: none;
      }
      .personnel-from-r-cont {
        margin: 0 auto;
        width: 100%;
        display: none;
      }
      .personnel-from-r-cont-m {
        display: block;
        width: 100%;
        // height: 75px;
        background: #f6f6f6;
        border-radius: 3px;
        padding: 20px 10px;
        h3 {
          text-align: center;
          font-size: 25px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #ff5a00;
          span {
            font-size: 12px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #999999;
          }
        }
        p {
          font-size: 12px;
          color: #616266;
          text-align: center;
        }
        span {
          font-size: 15px;
          font-weight: bold;
          color: #ff5a00;
        }
      }
      .personnel-from-test-b {
        text-align: center;
        margin: 15px 0px;
      }
    }
    .personnel-from-l {
      width: auto;
      padding: 0;
      margin: 0 auto;
      .ant-select {
        width: auto !important;
      }
    }
    .personnel-from-c {
      display: none;
    }

    .personnel-swiper {
      .personnel-box {
        flex-wrap: wrap;
        .personnel-swiper-l {
          width: 100%;
          margin: 0 auto;
          margin-bottom: 30px;
          .swiper-slide {
            width: 100% !important;
          }
          img {
            margin: 0 auto;
            width: 100%;
          }
        }
        .personnel-swiper-r {
          margin: 0 auto;
          h1 {
            margin-top: 20px;
            .test-t {
              top: auto;
            }
          }
          .personnel-swiper-r-cent {
            flex-wrap: wrap;
            margin-top: 60px;
            .personnel-swiper-r-cent-list {
              width: 50%;
              .box {
                p {
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }

    .personnel-serve {
      padding: 35px 0;
      .personnel-serve-box {
        h1 {
          font-size: 25px;
        }
        .personnel-serve-box-img {
          display: flex;
          align-items: center;
          height: 350px;
          .personnel-serve-box-img-bg {
            display: none;
          }
          .personnel-serve-box-img-bg-m {
            display: block;
            margin: auto;
            img {
              width: 100%;
            }
          }
        }
        .btn-box {
          .btn {
            width: 152px;
            height: 40px;
            font-size: 13px;
            border: 1px solid #ffffff;
            border-radius: 5px;
            line-height: 40px;
          }
        }
      }
    }

    .personnel-servepick {
      padding: 35px 0;
      .container {
        padding: 0;
      }
      &-box {
        h1 {
          font-size: 25px;
        }
        h3 {
          font-size: 12px;
        }
        .servepick-pc {
          display: none;
        }
        .servepick-m {
          & > div {
            margin-top: 35px;
          }
          display: block;
        }
      }
    }

    .personnel-shcool {
      padding: 40px 0;
      h1 {
        font-size: 25px;
      }
      h3 {
        font-size: 13px;
      }
      .personnel-shcool-cent {
        flex-wrap: wrap;
        margin-top: 35px;
        .personnel-shcool-box-l {
          width: 100%;
          img {
            width: 100%;
          }
          margin-bottom: 30px;
        }
        .personnel-shcool-box-r {
          width: 100%;
          .list-img {
            width: 100%;
            height: auto;
          }
          .list {
            width: 48%;
          }
        }
      }
    }
  }

  .study-btn {
    margin-bottom: 50px;
    button {
      width: 152px;
      height: 40px;
      line-height: 40px;
      font-size: 13px;
    }
  }
}
</style>
