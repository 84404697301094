<template>
	<div class="modal-pop flex justify-center align-center animated fadeIn fast" @click.self="modalHide">
		<div class="pop-mian">
			<div class="pop-title text-center">已成功计算出您的报价!</div>
			<div class="pop-test">
				<p>稍后将由资质顾问通过电话承报给您优普道 为您竭诚服务！</p>
			</div>
			<div class="flex justify-center align-center pb-3">
				<button class="pop-btn" @click="modalHide">确定</button>
			</div>
			
		</div>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				
			}
		},
		methods:{
			modalHide(){
				this.$emit('modalHide')
			}
		}
	}
</script>

<style scoped="scoped" lang="scss">
	.modal-pop{
		z-index: 999;
		width: 100%;
		height: 100%;
		position: fixed;
		background-color: rgba(0,0,0,0.7);
		.pop-mian{
			max-width: 483px;
			// height: 316px;
			background: #FFFFFF;
			border-radius: 16px;
			.pop-title{
				width: 483px;
				height: 107px;
				background: linear-gradient(-32deg, #265EFF 0%, #5380FF 100%);
				border-bottom-left-radius: 50%;
				border-bottom-right-radius: 50%;
				font-size: 36px;
				font-family: zihun143-zhengkuchaojihei;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 89px;
			}
			.pop-test{
				font-size: 21px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #3F4043;
				line-height: 43px;
				padding: 40px 60px;
				text-align: center;
			}
			.pop-btn{
				width: 181px;
				height: 39px;
				background: #3267FF;
				border-radius: 8px;
				font-size: 21px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 39px;
				margin: 0 auto;
			}
		}
	}
	
	@media only screen and (max-width: 1200px) {
		
	}
	
	@media only screen and (max-width: 1024px) {
		
	}
	
	@media only screen and (max-width: 750px) {}
	
	@media only screen and (max-width: 481px) {
		.modal-pop .pop-mian{
			width: 80%;
			.pop-title{
				width: 100%;
				font-size: 24px;
			}
			.pop-test{
				padding: 20px;
			}
		}
	}
</style>
